import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const GiraffePage = () => (
    <Layout>
        <Seo 
            title="Webflow website design &amp; development case study" 
            description="Case study of the website design and Webflow development work done for the new Giraffe website completed by Launch Lab."
        />

        <Hero
            h1="Webflow design and development case study"  
            h2="Design &amp; Development of the new Giraffe website"
        />

        
        <section className="bg-noise giraffe section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/giraffe.png"
                        alt="Screens from the Giraffe website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonial
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Giraffe is an Australian startup. To support their next phase of growth a new website was required.</p>
                                    <p>The old website had been developed on Webflow, and considering the website requirements and the founders experience using Webflow, it made sense to build the new website there too.</p>
                                    <p>The brief was to deliver a clean and professional website for a website visitor who is prepared to read long form text and wants to go deep on understanding Giraffe's powerful product.</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>We designed the website in Figma, and built it on Webflow with the focus being on:</p>
                                    <ul>
                                        <li>Have a strong focus on product imagery</li>
                                        <li>Don't stray from the existing brand</li>
                                        <li>Funnel the user into use cases, case studies and how to's</li>
                                        <li>Leverage the Webflow CMS as much as possible to enable the client to have as much content control of the website as possible.</li>
                                    </ul>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/web-design/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/webflow-developer/" className="btn btn-lg btn-line">Webflow development</Link>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonial</h4>
                                    <Testimonial
                                        quote="Looks amazing! Thanks for all your effort Shaun, we're very happy with the outcome!"
                                        name="Matt Weston"
                                        title="COO, Giraffe"
                                    />
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the Giraffe website.</p>
                                    <a href="https://www.giraffe.build/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Start a Webflow project"
            text="Get an obligation free web design or web development quote from an experienced local Australian development team. Whether it's a Webflow website or a full startup application build, our onshore team can help!"
        />
    </Layout>
)

export default GiraffePage